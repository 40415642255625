import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import tw from "twin.macro";
import { StaticImage } from "gatsby-plugin-image";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { EStateGeneric, formatMoney, formatMoney2, validEmailRegex } from "../../utils/general";
import {   EActionPage, useChat } from "./ChatContext";
import { SocketContext, SocketProvider } from "../Socket/SocketContext";
import imgAgent from "../../images/defaults/agent-default.png"
import { htmlToText } from 'html-to-text';
import axios from "axios";
import LoadingAnimation from "../Layout/LoadingAnimation";
import { ChatMode, ETicketChannel, ETicketStatus } from "./types";
import _ from "lodash"
import { Howl } from 'howler';

import moment from "moment"
import { fetchAllCustomers, fetchAllOrderStats, fetchAllTicketsByEmail, fetchAllTicketsByUser, fetchChatHistoryByConversationId, fetchTicketMessageHistoryByEmail, fetchTicketMessageHistoryByUser } from "./api";

/* const ChatAlertIncoming = () => {

  const { updateValues, isOpen } = useChat()
  const [hiddenMessageBot, setHiddenMessage] = useState("")

  const [status, setStatus] = useState(EStateGeneric.IDLE)
  useEffect(() => {
    if (localStorage.getItem("hiddenMessageBot") !== "null") {
      const value = localStorage.getItem("hiddenMessageBot") || ""
      setHiddenMessage(value)
    }
    setStatus(EStateGeneric.SUCCEEDED)
  }, [])

  useEffect(() => {
    localStorage.setItem("hiddenMessageBot", hiddenMessageBot)
  }, [hiddenMessageBot])


  if (hiddenMessageBot) return null

  if (status === EStateGeneric.IDLE)
    return null

  if (isOpen)
    return null

  return <motion.div
    initial={{ y: '100%', opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 0.5, delay: 3 }}
    css={tw`fixed bottom-0 right-0  px-4 mx-auto w-screen flex justify-center mb-4`} style={{
      zIndex: 99,
    }}>
    <div css={tw`bg-black rounded-lg text-sm p-2   lg:flex-row flex-col flex text-white flex items-center space-x-2`}>
      <span><span css={tw`font-bold`}>- Auto:</span> <span css={tw``}>¡Hey! ¿Necesitas algo? ¡Psts... Estoy aquí para ti! 🦾😉</span></span> <div>
        <button type="button" css={tw`text-autogestiones-100 font-bold hover:underline`} onClick={() => {
          updateValues({
            isOpen: true
          })
        }} style={{ marginTop: -2 }}>[Abre el chat]</button>
        <button type="button" css={tw`text-autogestiones-150 font-bold hover:underline ml-1`} onClick={() => {
          setHiddenMessage("accept")
        }} style={{ marginTop: -2 }}>[Cerrar]</button></div>
    </div>
  </motion.div >
}
 */

const Message = ({ message, messages, index }) => {
  const { sendMessageByWS } = useChat()
  const nextIsSame = messages[index - 1]?.direction === message?.direction
  const previousIsSame = messages[index + 1]?.direction === message?.direction
  const isAgent = message?.direction === "outgoing"
  const options = message?.payload?.options

  const [hover, setHover] = useState(false)
  if (isAgent) {
    return <>
      {message?.payload?.text?.length > 0 && <div onMouseOver={() => {
        setHover(true)
      }} onMouseLeave={() => {
        setHover(false)
      }} css={[tw`w-full flex gap-2`, tw`flex-row-reverse`]}>
        <div css={[tw`w-full    flex flex-col`]} >

          {!previousIsSame && <span style={{
            height: 15,
            marginBottom: 2
          }} css={[tw` text-left font-nunito text-xs w-full text-gray-400`]}>{message?.senderName || "Auto"}</span>}
          <div css={tw`flex gap-2 flex justify-start`}>

            <div style={{
              maxWidth: 190,
              borderRadius: nextIsSame && previousIsSame ? "2px 14px 14px 2px" : nextIsSame ? " 14px 14px 14px 2px" : " 2px 14px 14px 14px",
            }} css={[tw`bg-gray-500 bg-opacity-0 border border-gray-500 border-opacity-50 text-white px-2 py-3`]}>
              <div css={[tw`text-xs font-nunito break-words whitespace-pre-wrap`]} dangerouslySetInnerHTML={{
                __html: message?.payload?.text
              }}></div>
            </div>

          </div>

          {(index === 0 && options?.length > 0) &&
            <div css={tw`flex gap-1 w-full flex-wrap mt-1`}>
              {options?.map((x, i) => <button key={i} onClick={(e => {
                sendMessageByWS(x.value)
              })} css={tw`flex font-nunito bg-gray-600  bg-opacity-50 border-white border border-opacity-10  rounded-lg text-left   text-white   px-2 py-1 text-xs    hover:bg-gray-400`}>{x?.label}</button>)}
            </div>
          }
          {!nextIsSame && <span style={{
            height: 15,
            marginTop: 2
          }} css={[tw`text-left font-nunito text-xs w-full text-gray-400  `, !hover ? tw`opacity-0` : ""]}  >  {moment(message?.createdAt).format("MMM d, h:mm a")}</span>}

        </div>

        <img
          alt="Agente foto de perfil"
          style={{
            marginTop: 15,
            width: 21,
            height: 21,
            borderRadius: "100%"
          }} src={imgAgent} />

      </div>}

    </>
  }

  return <>
    <div css={[tw`w-full flex gap-2`, tw`flex-row`]}>
      <div css={[tw`w-full   flex flex-col`]}>

        <div css={tw`flex gap-2 flex justify-end`}>
          <div style={{
            maxWidth: 190,
            borderRadius: nextIsSame && previousIsSame ? "14px 2px 2px 14px" : nextIsSame ? " 14px 14px 2px 14px " : "14px 2px 14px 14px",
          }} css={tw`px-2 py-3 bg-autogestiones-100 text-white relative`}>
            <div css={[tw`text-xs font-nunito break-words whitespace-pre-wrap`]} dangerouslySetInnerHTML={{
              __html: message?.payload?.text
            }}></div>
            <div css={tw`absolute bottom-0 right-0  text-white text-opacity-50`} style={{
              fontSize: 11,
              marginRight: 5,
              marginBottom: 0
            }}>
              {(!message?.status || message?.status === EStateGeneric.SUCCEEDED) && <i className="fa-light fa-check"></i>}
              {message?.status === EStateGeneric.PENDING && <i className="fa-light fa-clock"></i>}
            </div>
          </div>
          {message?.status === EStateGeneric.FAIL &&
            <div css={tw`bg-red-500  w-5 h-5 my-auto flex justify-center items-center rounded-full text-white`}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          }
        </div>
        {!nextIsSame && <span style={{
          height: 15,
          marginTop: 2
        }} css={[tw`text-right font-nunito text-xs w-full text-gray-400`]}  >   {moment(message?.createdAt).format("MMM d, h:mm a")}</span>}


      </div>

    </div>
  </>
}

const MessageTyping = () => {


  return <>
    <div
      css={[tw`w-full flex gap-2`, tw`flex-row-reverse`]}>
      <div css={[tw`w-full  pr-8  flex flex-col`]}>

        <span style={{
          height: 15,
          marginBottom: 2
        }} css={[tw` text-left font-nunito text-xs w-full text-gray-400`]}>Auto</span>

        < div
          style={{
            borderRadius: " 2px 14px 14px 14px",
          }} css={[tw`bg-gray-600 animate-pulse flex px-2 py-1`]}>

          <div className="typing">
            <div className="typing__dot"></div>
            <div className="typing__dot"></div>
            <div className="typing__dot"></div>
          </div>

        </ div>


      </div>

      <img
        alt="Agente foto de perfil"
        style={{
          marginTop: 15,
          width: 21,
          height: 21,
          borderRadius: "100%"
        }} src={imgAgent} />

    </ div >
  </>
}

function generateAttachmentFormat(attachment) {
  let format = '';

  if (attachment.type === 'image') {
    format = `[Imagen] "${attachment.name}"\n ${attachment.url}`;
  } else if (attachment.type === 'link') {
    format = `[Enlace] "${attachment.name}"\n ${attachment.url}`;
  } else {
    format = `[Adjunto] "${attachment.name}"\n ${attachment.type}\nURL: ${attachment.url}`;
  }

  return format;
}

function convertLinksToAnchors(text) {
  // Expresión regular para buscar enlaces
  var linkRegex = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/g;

  // Reemplazar los enlaces encontrados por enlaces HTML
  var convertedText = text.replace(linkRegex, function (match) {
    return `<a  class="dark-blue-link" target="_blank" href="${match}">${match}</a>`;
  });

  return convertedText;
}

function msgTicketFormat(message) {
  const { attachments }: { attachments: any[] } = message
  let text = htmlToText(message.text)
  text = text?.trim()
  if (attachments?.length > 0) {
    if (text) {
      text += "\n"
      text += "------"
    }
    attachments.forEach(attachment => {
      text += "\n"

      text += generateAttachmentFormat(attachment)
      text += "\n"

    })
  }

  text = text?.trim()

  text = convertLinksToAnchors(text)

  return ({
    id: message.id,
    payload: {
      text,
    },
    type: "text",
    direction: !message.isAnswer ? "incoming" : "outgoing",
    createdAt: message.createdAt,
    senderName: message?.senderName?.split(" ")[0]
  })
}

function msgConversationFormat(message) {
  let text = htmlToText(message.payload?.text)
  text = text?.trim()


  text = convertLinksToAnchors(text)

  return ({
    ...message,
    payload: {
      ...message?.payload,
      text
    }
  })
}


const HeaderChat = () => {

  const { messages, mode } = useChat()
  const agentName = useMemo(() => {
    if (mode === ChatMode.SUPPORT_AGENT) {
      return messages.find(x => x.direction === "outgoing")?.senderName || "Agente"
    }

    return "Auto"

  }, [messages.length, mode])
  return <>
    <div css={tw`w-full bg-gray-700 items-center gap-3 flex rounded-t-lg px-2  bg-opacity-75 border-b border-gray-600`} style={
      {
        zIndex: 100,
        position: "absolute",
        backdropFilter: "blur(5px)",
        height: 50,
        "-webkit-backdrop-filter": "blur(5px)",
      } as any
    }
    >
      <img

        alt="Agente foto de perfil"
        style={{
          width: 34,
          height: 34,
          borderRadius: "100%",
        }}

        src={imgAgent} />
      <span css={tw`text-sm`}>{agentName || "Auto"}</span>
    </div>
  </>
}
const Conversation = () => {
  const { socket, online } = useContext(SocketContext)
  const { setLoading, setMessages, messages, conversationId, mode, ticketId, user, loading, updateValues } = useChat()
  const timer: any = useRef(null); // we can save timer in useRef and pass it to child
  const [isTyping, setIsTyping] = useState(false);
  const messagesContainerRef = useRef<any>(null);

  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    if (!container) return
    container.scrollTop = container.scrollHeight;
  };

  const notificationSound = new Howl({
    src: ['/sounds/sound3.mp3'],
    volume: 0.5, // Ajusta el volumen según tus preferencias
  });

  useEffect(() => {
    if (online && conversationId) {

      const emitMeetingChat = () => {




        socket?.off("chat-messages")
        socket?.off("update-chat")

        socket?.emit("meeting-chat", {
          conversationId: conversationId,
        });


        socket?.on("chat-messages", msg => {
          if (mode === ChatMode.CHATBOT) {
            notificationSound.play();
            console.log("a")
            console.log(msg)
            setIsTyping(true)
            setTimeout(() => {
              setMessages(x => [msgConversationFormat(msg), ...x] as any)
              setIsTyping(false)
              scrollToBottom()
            }, 500);
          }
        })

        socket?.on("update-chat", (msg: {
          type: string
          ticketId: string
        }) => {
          if (msg.type === "ticket-created") {
            updateValues({
              mode: ChatMode.HOME,
              ticketId: msg.ticketId
            })

            setTimeout(() => {
              updateValues({
                mode: ChatMode.SUPPORT_AGENT,
                ticketId: msg.ticketId,
              })
            }, 500);

          }
        })


      };

      // Emitir el mensaje cuando se monte el componente y conversationId exista
      if (conversationId) {
        emitMeetingChat();
      }

      // Devolver una función de limpieza para detener el meeting-chat anterior al desmontar el componente

    }
  }, [online, conversationId]);



  useEffect(() => {
    if (socket && online) {
      socket.emit('meeting-support', { ticketId: ticketId });

      socket.off("ticket_message")

      if (ticketId && ticketId?.length > 0) {
        socket.on("ticket_message", (data) => {
          setMessages(messages => {

            const messageToAdd = msgTicketFormat(data?.message)

            if (messages?.some(message => message.id === data?.message?.id))
              return messages
            if (messageToAdd.direction === "incoming")
              return messages

            return _.uniqBy([messageToAdd, ...messages], x => x.id)
          })
          scrollToBottom()
        });

        socket?.on("typing-status", msg => {
          console.log(msg)
          if (msg?.direction === "incoming") {
            return
          }



          setIsTyping(msg.isTyping)
          if (msg.isTyping) {
            // Reiniciar el temporizador cada vez que el usuario escriba algo nuevo
            if (timer.current) {
              clearTimeout(timer.current);
            }
            timer.current = setTimeout(() => {
              setIsTyping(false);
            }, 3500);
          }

        })
      }
    }
  }, [socket, online, ticketId])

  useEffect(() => {
    const fn = async () => {
      setLoading(true)
      try {
        setMessages([])
        if (mode === ChatMode.CHATBOT) {
          //TODO: Evaluar si tiene conversación
          if (conversationId) {
            //Si tiene se cargará esa conversación
            try {
              const response = await fetchChatHistoryByConversationId(conversationId)
              setMessages(response.data?.messages?.map(x => msgConversationFormat(x)))

              return

            } catch (err) {
              console.error(err?.response)
            }

          }

          //Creará
          //
        } else if (mode === ChatMode.SUPPORT_AGENT && ticketId && user) {
          const res = user.id ? await fetchTicketMessageHistoryByUser(ticketId) : await fetchTicketMessageHistoryByEmail(ticketId, user.email)

          const messages = res.data.messages?.map(message => {
            return msgTicketFormat(message)
          })



          setMessages(messages)

        }

      } catch (err) {
        console.error(err)
        updateValues({
          ticketId: null,
          mode: ChatMode.HOME
        })

      } finally {
        setLoading(false)

      }
    }


    fn()

  }, [])



  let content
  if (loading) {
    content = <LoadingAnimation />
  } else {
    content = <>
      {isTyping && <MessageTyping key={-1} />}
      {messages?.map((x, i, arr) => <Message key={i} index={i} message={x} messages={arr} />)}
    </>
  }

  return <>
    <div css={tw`flex flex-col relative mt-1`}>
      <HeaderChat />
      <div className="scroll-v"

        ref={messagesContainerRef}
        css={tw`bg-gray-700   flex flex-col-reverse gap-1 overflow-auto mt-1 rounded-t-lg px-3 `} style={{
          height: 350,
          paddingTop: 60,
          paddingBottom: 10,
        }}>
        {content}
      </div>

    </div>
    <BoxMessage />

  </>

}


const BotHelpButton = () => {
  const { sendMessageByWS, isOpen, setAnswer, updateValues, answer } = useChat()
  return <button
    onClick={() => {
      setAnswer(null)
      updateValues({
        mode: ChatMode.CHATBOT,
      })
      sendMessageByWS("hola")
    }}
    css={tw`bg-autogestiones-100 w-full text-sm  text-white  flex   justify-center items-center gap-1 overflow-auto mt-1 rounded-lg px-3 `} style={{
      height: 45,
      marginTop: 10
    }} className="hover:brightness-110">
    <i className="fa-solid fa-headset" css={tw` `}></i>
    Recibir soporte
  </button>
}
const Answer = () => {
  const { socket, online } = useContext(SocketContext)
  const { conversationId, isOpen, setAnswer, updateValues, answer } = useChat()

  const previousIsSame = true
  const nextIsSame = true
  const index = 0
  const messages = [
    {
      type: "text",
      payload: {
        text: answer
      },
      direction: "outgoing"
    }
  ]


  const options = []

  return <>
    <div css={tw`flex flex-col relative mt-1`}>
      <div css={tw`w-full bg-gray-700 items-center gap-3 flex rounded-t-lg px-2  bg-opacity-75 border-b border-gray-600`} style={
        {
          position: "absolute",
          backdropFilter: "blur(5px)",
          height: 50,
          "-webkit-backdrop-filter": "blur(5px)",
        } as any
      }
      >
        <img
          alt="Agente foto de perfil"
          style={{
            width: 34,
            height: 34,
            borderRadius: "100%"
          }} src={imgAgent} />
        <span css={tw`text-sm`}>Auto</span>
      </div>
      <div className="scroll-v" css={tw`bg-gray-700   flex flex-col-reverse gap-1 overflow-auto mt-1 rounded-lg px-3 `} style={{
        height: 350,
        paddingTop: 60,
        paddingBottom: 10,
      }}>
        <div css={[tw`w-full flex gap-2`, tw`flex-row-reverse`]}>
          <div css={[tw`w-full  pr-8  flex flex-col`]}>

            {!previousIsSame && <span style={{
              height: 15,
              marginBottom: 2
            }} css={[tw` text-left font-nunito text-xs w-full text-gray-400`]}>Auto</span>}

            <div style={{
              borderRadius: nextIsSame && previousIsSame ? "2px 14px 14px 2px" : nextIsSame ? " 14px 14px 14px 2px" : " 2px 14px 14px 14px",
            }} css={[tw`bg-gray-500 bg-opacity-0 border border-gray-500 border-opacity-50 text-white px-2 py-3`]}>
              <div css={[tw`text-xs font-nunito break-words whitespace-pre-wrap`]} dangerouslySetInnerHTML={{
                __html: answer || ""
              }}></div>
            </div>
            <div css={tw`flex gap-1 w-full flex-wrap mt-1`}>
              <button className="  hover:brightness-110" onClick={(e => {
                setAnswer(null)
              })} css={tw`flex font-nunito bg-gray-600 bg-opacity-50 border-white border border-opacity-10 rounded-lg text-left   text-white   px-2 py-1 text-xs  `}>Volver a inicio</button>
            </div>
          </div>

          <img
            alt="Agente foto de perfil"
            style={{
              marginTop: 15,
              width: 21,
              height: 21,
              borderRadius: "100%"
            }} src={imgAgent} />

        </div>
      </div>

    </div>
    <BotHelpButton />

  </>

}

const Login = () => {
  const { updateValues, setOnAfterLogging } = useChat()

  const [step, setStep] = useState(0)
  const [email, setEmail] = useState("")

  const handleJoinGuest = () => {
    updateValues({
      user: {
        email
      }
    })
  }

  const step0 = <>
    <div css={tw`flex gap-2`}>
      <i className="fa-solid fa-circle-exclamation" css={tw`mt-1`}></i>
      <div css={tw` text-sm`} >
        Inicia sesión para guardar los detalles importantes en tu historial de chat. Esto nos ayudará a brindarte un mejor servicio y mejorar tu experiencia de chat.
      </div>
    </div>

    <button css={tw`bg-autogestiones-100 text-sm rounded-lg py-2 text-white px-4 hover:bg-autogestiones-50`} onClick={() => {
      const fn = () => {
        setTimeout(() => {
          updateValues({
            isOpen: true,
          })
        }, 300);
      }

      setOnAfterLogging(() => fn)
      updateValues({
        actionPage: EActionPage.LOGIN_SCREEN,
        isOpen: false,

      })
    }}>Iniciar sesión (recomendado)</button>
    <button css={tw`bg-gray-500 text-sm rounded-lg py-2 text-white px-4 hover:bg-gray-600`} onClick={() => {
      setStep(1)

    }}  >Continuar como visitante</button>
  </>

  const step1 = <>
    <div css={tw`flex gap-2`}>
      <i className="fa-solid fa-envelope" css={tw`mt-1`}></i>
      <div css={tw` text-sm`} >
        Ingresa tu dirección de correo electrónico para recibir actualizaciones sobre tu consulta.
      </div>
    </div>
    <input type="email"
      value={email}
      onChange={x => setEmail(x.currentTarget.value)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          // Aquí puedes realizar la acción que desees cuando se presione Enter
          handleJoinGuest()
        }
      }}
      style={{
        resize: "none",
        height: 20,
        maxHeight: 200,
        overflowY: "hidden"
      }}

      placeholder="Por favor, ingrese tu correo electrónico"
      css={tw`w-full bg-transparent focus:outline-none bg-autogestiones-300 rounded-lg p-4`} />
    <button disabled={!validEmailRegex.test(email)} css={[!validEmailRegex.test(email) ? tw`bg-autogestiones-100  opacity-25` : tw`bg-autogestiones-100 hover:bg-autogestiones-50 
    `, tw`text-sm rounded-lg py-2 text-white px-4 `]} onClick={handleJoinGuest}>Iniciar chat</button>
  </>

  return <div style={{
    zIndex: 1000
  }} css={tw`absolute font-nunito bottom-0 flex flex-col justify-end h-full w-full bg-black bg-opacity-25 `}>
    <div css={tw`bg-gray-700 px-4 py-4  gap-2 flex flex-col`}>
      {step === 0 && step0}
      {step === 1 && step1}

    </div>
  </div>
}

const TicketStatus = ({ value }) => {
  if (value === ETicketStatus.ANSWERED)
    return <>
      <span css={tw`text-green-200 font-bold rounded border border-green-400 bg-green-400 bg-opacity-10`}>Respondido</span>
    </>
  if (value === ETicketStatus.CLOSED)
    return <>
      <span css={tw`text-gray-200 font-bold rounded border border-gray-400 bg-gray-400 bg-opacity-10`}>Cerrado</span>
    </>
  if (value === ETicketStatus.UNANSWERED)
    return <>
      <span css={tw`text-gray-200 font-bold rounded border border-gray-400 bg-gray-400 bg-opacity-10`}>Sin responder</span>
    </>

  return null

}
const Home = () => {
  const { socket, online } = useContext(SocketContext)
  const { user, loading, setAnswer, setTickets, tickets, updateValues, sendMessageByWS, ticketId } = useChat()

  const refreshTickets = async () => {
    try {
      const res = user?.id ? await fetchAllTicketsByUser({
        take: 10,
        channel: ETicketChannel.CHAT
      }) : user?.email ? await fetchAllTicketsByEmail(user?.email) : null

      console.log(res?.data)
      setTickets(res?.data?.tickets || [])
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (socket && online) {

      socket?.off("typing-status")
      socket.off("ticket_message")


    }
  }, [socket, online])

  useEffect(() => {

    // Configurar un intervalo para llamar a handleFetch cada 15 segundos (15000 ms)
    if (user?.id) {
      refreshTickets();
      const intervalId = setInterval(() => {
        refreshTickets();
      }, 15000);

      // Limpiar el intervalo cuando el componente se desmonte
      return () => {
        clearInterval(intervalId);
      };
    }

  }, [user?.id]);


  const actions = [
    {
      label: "Ver estado de Autocuentas",
      onClick: async () => {
        try {
          const response = await fetchAllCustomers()

          const validate = (x) => !x.tags?.includes("FROZEN") && x.subscriptionStatus === "ACTIVE" ? `✅` : `❌`
          const customers = response.data.customers
          setAnswer(`A continuación el estado de tus Cuentas de Autogestiones Cloud\n${customers.map(x => `<div>- ${x?.name} ${validate(x)}</div>`).join("\n")}`)
        } catch (err) {
          setAnswer("No se ha podido ver estado")
        }
      }
    },
    {
      label: "Obtener saldo a pagar",
      onClick: async () => {
        try {
          const response = await fetchAllOrderStats()

          const balance = response.data.balance.filter(x => x.totalAmount)

          if (balance?.length > 0) {
            setAnswer(`Usted adeuda: \n${balance.map(x => `<div>- ${formatMoney2(x?.totalAmount, x.currency)} </div>`).join("\n")}`)

          } else {
            setAnswer(`😄 No tienes deuda en el sistema. ¡Todo está en orden! Si tienes alguna pregunta, no dudes en preguntar. 🌟`)
          }

        } catch (err) {
          console.error(err)
          setAnswer("No se ha podido ver estado")
        }
      }
    },
  ]

  const handleTicket = (id) => () => {
    updateValues({
      mode: ChatMode.SUPPORT_AGENT,
      ticketId: id,
    })

  }


  let content

  if (loading) {
    content = <LoadingAnimation />
  }
  else {
    content = <>
      <div css={tw`flex mx-auto flex-col items-center `}>
        <img
          alt="Agente foto de perfil"
          style={{
            height: 100,
            width: 100,
            borderRadius: "100%"
          }} src={imgAgent} />
        <div css={tw`mt-2 text-sm text-white text-opacity-50`}>
          Asistente IA
        </div>
        <div css={tw`mt-0 mb-2`}>
          ¿Qué puedo hacer por ti?
        </div>
      </div>

      {user?.id && <>
        <div css={tw`  w-full flex text-sm text-white text-opacity-50 border-t border-gray-500 pt-2`}>
          <h3>Autogestión</h3>
        </div>
        <div css={tw`w-full grid grid-cols-2 text-xs font-nunito mt-1 gap-2`}>
          {actions.map((x, i) => <button onClick={x.onClick} key={i} className="hover:brightness-110" css={tw`bg-autogestiones-300  rounded-lg text-white py-1  `}>{x.label}</button>)}
        </div>
      </>}
      {tickets?.length > 0 && <> <div css={tw`  w-full mt-2 flex text-sm text-white text-opacity-50 border-t border-gray-500 pt-2`}>
        <h3>Últimos tickets</h3>
      </div>
        <div css={tw`w-full grid grid-cols-1 text-xs font-nunito mt-1 gap-2`}>
          {tickets.map((x, i) =>
            <button onClick={handleTicket(x.id)} key={i} className="hover:brightness-110" css={tw`bg-autogestiones-300  rounded-lg text-white py-1 px-4  `}>
              {<TicketStatus value={x.status} />}  Ticket #{x.id}: {x.subject}
            </button>)}
        </div> </>}
    </>
  }


  return <><div css={tw`flex flex-col relative mt-1`}>


    <div className="scroll-v" css={tw`bg-gray-700  pt-4 flex flex-col     gap-1 overflow-auto mt-1 rounded-t-lg px-3 `} style={{
      height: 350,
      paddingBottom: 25,
    }}>
      {content}
    </div>

  </div>

    <BotHelpButton />

  </>
}

const BoxMessage = () => {
  const [text, setText] = useState("")
  const timer: any = useRef(null); // we can save timer in useRef and pass it to child

  const fileRef = useRef(null)
  const { socket, } = useContext(SocketContext)
  const { sendFileByWS, sendMessageByWS, ticketId, user, mode } = useChat()

  const sendTypingStatus = (value) => {
    if (!socket) return null
    // Aquí deberías utilizar tu función para emitir el estado de escritura al servidor (por ejemplo, socket.emit)
    socket.emit("ticket-typing-status", { isTyping: value, direction: "incoming", ticketId: ticketId });
    console.log("Sending typing status to the server:", value);
  };



  const handleInputChange = () => {
    sendTypingStatus(true);
    // Reiniciar el temporizador cada vez que el usuario escriba algo nuevo
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      sendTypingStatus(false);
    }, 500);
  };



  return <> <div css={tw`flex flex-col-reverse   relative   w-full`
  } style={{
    zIndex: 100,
    height: 53,
  }} className=" " >
    <div
      style={{
        boxShadow: "0 -2px 4px 0 rgba(10,10,10,.1)",
        paddingRight: 40,
      }}
      css={tw`font-nunito text-sm bg-gray-700  relative  rounded-b w-full  pt-4 pb-3 px-4`}>

      <textarea
        value={text}
        onChange={x => {
          const value = x.currentTarget.value
          handleInputChange()
          setText(value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault(); // Opcional: para evitar que se agregue un carácter de tabulación al contenido editable
            sendMessageByWS(e.currentTarget.value)
            setText("")
            e.currentTarget.style.height = "20px";
            // handleSubmit()
          }
        }} placeholder="Escriba mensaje..." /* value={values.text}  */ onInput={(e) => {
          //   setFieldValue("text", e.currentTarget.value)
          e.currentTarget.style.height = "5px";
          e.currentTarget.style.height = (e.currentTarget.scrollHeight) + "px";
        }} style={{
          resize: "none",
          height: 20,
          maxHeight: 200,
          overflowY: "hidden"
        }} css={tw`w-full bg-transparent focus:outline-none `}></textarea>

      {
        mode === ChatMode.SUPPORT_AGENT && user?.id && <>
          <button
            onClick={() => {
              if (fileRef && fileRef.current) {
                (fileRef.current as any).click()
              }
            }}
            css={tw`absolute cursor-pointer text-white text-opacity-50 top-0 right-0 mr-3 mt-4`}>
            <i className="fa-solid fa-paperclip" style={{
              fontSize: 18
            }}></i>
          </button>
          <input ref={fileRef} type="file" css={tw`hidden`} onChange={e => sendFileByWS(e, fileRef)} accept=".jpg, .jpeg, .png, .gif, .svg, .xlsx, .csv, .mp4, .avi, .mov, .txt, .pdf, .doc, .docx, .mp3, .ogg, .aac, .wma, .zip, .rar, .7z" />
        </>
      }
    </div>

  </div>

  </>
}

const Chat = () => {
  const { isOpen, mode, updateValues, ticketId, user: user, loading, isLogged, answer, setAnswer } = useChat()


  const { socket, online } = useContext(SocketContext)

  // Manejar el evento onChange del campo de texto para actualizar el estado de escritura

  return <motion.div
    exit={{
      opacity: 0,
      scaleX: 0,
      height: 0
    }}
    animate={
      {
        opacity: 1,
        height: 555,
        scaleX: 1,
      }
    }
    initial={{
      opacity: 0,
      height: 0,
      scaleX: 0,
    }}
    transition={{
      ease: "linear",
    }}
    style={{
      zIndex: 100000
    }} css={tw`fixed bottom-0   right-0  lg:w-chat    w-full px-2       lg:mb-4 lg:mr-4 py-2     `}>
    <div css={tw` flex justify-end gap-2  `}>
      {(mode !== ChatMode.HOME || answer) &&
        <button
          style={{
            // background: "var(--primary-color)",
            height: 30,
          }}
          onClick={() => {
            updateValues({ mode: ChatMode.HOME })
            setAnswer(null)
          }}
          className=" hover:brightness-110"
          css={tw`flex bg-gray-600 text-white rounded-lg mb-2  px-2 text-sm justify-center text-white  items-center`}

        >
          Volver a inicio
        </button>}
      <button
        className=" hover:brightness-110"
        style={{
          // background: "var(--primary-color)",
          height: 30,
          width: 40
        }}
        onClick={() => {
          updateValues({ isOpen: !isOpen })
        }}
        css={tw`flex bg-gray-500 rounded-lg mb-2   text-sm   justify-center text-white  items-center`}

      >
        X
      </button>
    </div>
    <div css={tw`   shadow-lg  relative rounded-md  w-full bg-gray-800  `}>


      <div style={{
        zIndex: 1,
        height: 110
      }} css={tw`bg-autogestiones-100 rounded-t absolute font-bold bg-opacity-25 absolute top-0 py-4 w-full  `}>
      </div>


      <div style={{
        zIndex: 22
      }} css={tw`px-3 py-3 w-full  relative `}>

        {!online ? <div css={tw`cursor-pointer justify-center text-white text-center items-center mb-2  bg-red-500 flex      `}
          className="light-sm"
          style={{
            borderRadius: "100% 8px 100% 100% ",
            height: 30,
            width: 30
          }}>
          <i className="fa-solid fa-message-xmark" css={tw` text-xs`} ></i>
        </div> : <div css={tw`cursor-pointer justify-center text-white text-center items-center mb-2  bg-green-500 flex      `}
          className="light-sm"
          style={{
            borderRadius: "100% 8px 100% 100% ",
            height: 30,
            width: 30
          }}>
          <i className="fa-solid fa-message-lines" css={tw` text-xs`} ></i>
        </div>}

        <div css={tw`flex flex-col text-white `}>

          {online ? <span css={tw`font-bold text-sm`}>{mode === ChatMode.SUPPORT_AGENT ? `Caso #${ticketId}` : `Chat en vivo`}</span> : <button onClick={() => {
            window.location.reload();
          }} css={tw`font-bold text-sm text-red-400 text-left`} >Desconectado. Haga click para conectar</button>}
          <span css={tw`font-nunito mt-0 text-gray-400 `} style={{
            fontSize: 11,
            fontWeight: 700
          }}>Consultanos lo que necesites las 24 horas y los 7 días </span>
        </div>
        {answer ? <Answer /> : mode === ChatMode.HOME ? <Home /> : <Conversation />}

      </div>

      {!isLogged && <Login />}
    </div>
  </motion.div >
}



const ChatButton = () => {
  const { conversationId, isOpen, mode, updateValues } = useChat()


  return <>
    <motion.div

      exit={{
        opacity: 0,
        scale: 0
      }}
      initial={{
        opacity: 0,
        scale: 0
      }}
      animate={{
        opacity: 1,
        scale: 1
      }}

      onClick={() => { updateValues({ isOpen: !isOpen }) }} css={tw`cursor-pointer shadow-lg fixed right-0 bottom-0 cursor-pointer justify-center text-white text-center items-center animate-bounce bg-autogestiones-200 flex  mr-5 mb-5  `}
      className="alight-sm"
      style={{
        borderRadius: "100% 10px 100% 100% ",
        zIndex: 10000,
        height: 50,
        width: 50
      }}>
      <i className="fa-solid fa-message-lines" css={tw`animate-bounce`} ></i>
    </motion.div >
  </>
}


const ChatContainer = () => {

  const { updateValues, ...values } = useChat()

  return <>
    {!!( values?.showLoader) && <div css={tw`bg-gray-600  bg-opacity-75 w-screen h-screen text-5xl text-gray-300 flex justify-center items-center fixed top-0`}>
      <div css={tw`flex flex-col items-center text-center`}>
        <div className="flex">
        <i className="fa-loader fa-regular   animate-spin "></i>
        </div>
        <span css={tw`font-nunito text-xl font-bold`}>Procesando...</span>
      </div>
    </div>}
    <AnimatePresence>
      {values.isOpen ? <Chat key={3} /> :
        <ChatButton key={2} />}
      {/*  <ChatAlertIncoming key={1} /> */}
    </AnimatePresence >
    {/*  <div css={tw`text-white fixed bottom-0 left-0 bg-red-600`} style={{
      left: 400
    }}>
      {JSON.stringify(values)}
    </div> */}
  </>


}
export default ChatContainer